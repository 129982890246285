'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get(
      `/category/getCategoryList?language=${params.language}&moduleCode=${params.moduleCode}`
    );
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/role/authority/${roleId}`, { code });
  },
  add(code, data) {
    return http.post('/category/addOrUpDate', data, { code });
  },
  update(code, data) {
    return http.post('/category/addOrUpDate', data, { code });
  },
  delete(code, params) {
    return http.delete(`/category/delete?id=${params}`);
  },
  saveListToRole(code, data) {
    return http.post('/authority/role/authority', data, { code });
  },
};
