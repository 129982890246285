'use strict';

import http from '@/utils/http';

export default {
  getList(code, params) {
    return http.get('/authority/field/list', { params, code });
  },
  getListByRoleId(code, roleId) {
    return http.get(`/authority/fieldRule/authority/${roleId}`, { code });
  },
  saveListToRole(code, data) {
    return http.post('/authority/fieldRule/authority', data, { code });
  },
};
